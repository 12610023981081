import { tokenimg, tree4 } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";

import {
  AirdropText,
  AirDropTitle,
  ImgDiv,
  ImgWrapper,
  MainSection,
  Text,
  Title,
  TopTitle,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const TokenAirdropSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <AnimationOnScroll animateIn="animate__flipInX" animateOnce>
          <Title>
            ARGANACOIN
            <br />
            <p>Investir Dans ARG</p>
          </Title>
        </AnimationOnScroll>
        <ImgDiv>
          <Image src={tree4} />
        </ImgDiv>
        <MainRow className="main-row">
          <MainCol lg={7}>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <TopTitle>JETON ARGANACOIN - ARG</TopTitle>
              <Text>
                <div style={{ marginLeft: "-1rem" }}>
                  <ul>
                    <li>
                      Un total de <span>180,000,000 ARG </span>(offre totale).
                    </li>
                    <li>
                      <strong>10 %</strong> de l'offre, soit 18 000 000{" "}
                      <strong>ARG</strong>, est alloué à l'ICO.
                    </li>
                    <li>
                      La prévente se déroulera sur <strong>24 mois</strong>,
                      avec une distribution de{" "}
                      <strong>9 000 000 de jetons ARG</strong> par an.
                    </li>
                    <li>
                      Au cours des 12 premiers mois, <strong>7 000 000</strong>{" "}
                      de jetons ARG seront alloués à un prix préférentiel de{" "}
                      <strong>0,25 €/c</strong>, soit 0,27 USDT.
                    </li>
                    <li>
                      Au cours des 12 derniers mois, <strong>7 000 000</strong>{" "}
                      de jetons ARG seront alloués à un prix préférentiel de{" "}
                      <strong>0,50 €/c</strong>, soit 0,54 USDT. Le prix sera
                      ajusté en fonction de l’état d’avancement des projets et
                      de la valeur ajoutée.
                    </li>
                    <li>
                      Moins d'un quart de l'offre destinée à l'ICO, soit environ{" "}
                      <strong>4 000 000</strong> de jetons <strong>ARG</strong>{" "}
                      sur <strong>24</strong> mois, sera réservé aux{" "}
                      <strong>500</strong> premiers clients/investisseurs à un
                      prix proportionnel de :
                    </li>
                    <li>
                      <strong>0,20 €/c</strong> (0,22 USDT) pendant les{" "}
                      <strong>12 premiers mois.</strong>
                    </li>
                    <li>
                      <strong>0,45 €/c</strong> (0,49 USDT) au cours des{" "}
                      <strong>12 derniers mois.</strong>
                    </li>
                  </ul>
                </div>
              </Text>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={5}>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
              <ImgWrapper>
                <Image src={tokenimg} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
          <AirDropTitle>AIRDROP ET PROMOTION</AirDropTitle>
          <AirdropText>
            <ul>
              <li>
                Un quart de l’offre allouée à la prévente, soit{" "}
                <strong>4 000 000 de jetons ARG,</strong> sera réservé aux{" "}
                <strong>500</strong> premiers clients/investisseurs à un tarif
                préférentiel.
              </li>
              <li>
                Les investisseurs et clients participant et soutenant Arganacoin
                seront récompensés pour leurs efforts de communication sur les
                réseaux sociaux et la confiance qu’ils accordent au projet.
              </li>
              <li>
                Des jetons <strong>ARG</strong> seront utilisés pour les
                campagnes de marketing, les partenariats et les initiatives
                promotionnelles visant à accroître la visibilité du projet
                Arganacoin.
              </li>
            </ul>
          </AirdropText>
        </AnimationOnScroll>
      </MainContainer>
    </MainSection>
  );
};

export default TokenAirdropSection;
