import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    padding-bottom: 6rem;
  }
`;

export const ImgDiv = styled.div`
  text-align: end;
  margin-right: 7rem;
  @media (max-width: 990px) {
    display: none;
  }
`;

export const Title = styled.h5`
  margin-top: -9rem;

  color: rgba(61, 64, 68, 0.35);
  text-align: center;
  font-family: "Zen Dots";
  font-size: 58.917px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58.917px */
  text-transform: capitalize;
  @media (max-width: 990px) {
    margin-top: -3rem;
  }

  @media (max-width: 767px) {
    font-size: 40px;
  }

  p {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 29.459px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 29.459px */
    text-transform: capitalize;
    transform: translateY(-1rem);

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  &.title2 {
    margin-top: -1.5rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;
  margin-top: 5rem;

  @media (max-width: 767px) {
    margin-top: 1rem;

    .img1 {
      display: none;
    }
  }

  @media (min-width: 767px) {
    .img2 {
      display: none;
    }
  }
`;

export const ImgDiv2 = styled.div`
  margin-top: -4rem;
  margin-left: 2rem;

  @media (max-width: 990px) {
    display: none;
  }
`;
