import React from "react";
import { MainWrapper } from "./elements";
import environment from "environment";
import metamaskimg from "../../../assets/images/metamaskimg.png";
import { Image } from "react-bootstrap";
const CASection = () => {
  return (
    <>
      <MainWrapper>
        <h5>
          <span>ADRESSE DU CONTRACT-ICO :</span>
          <br />
          {/* {environment.ARGSALE[environment.DEFAULT_CHAIN]} */}
          XXXXXXXXXXX FUTUR ICO XXXXXXXXXXX
        </h5>
        <h5>
          <span>
            <Image src={metamaskimg} alt="metamaskimg" width={40} /> PARAMETRE
            VOTRE JETON ARG SUR METAMASK :
          </span>
          <br /> 0xA7134A773588184e7F2d4cc2E6A2fC0ce329761C
        </h5>
      </MainWrapper>
    </>
  );
};

export default CASection;
