import { video } from "assets";
import { MainContainer } from "components/common";
import React from "react";
import {
  MainSection,
  MainWrapper,
  MobileWrapper,
  Text,
  Title,
  VideoWrapper,
} from "./elements";
import Vimeo from "@u-wave/react-vimeo";
const VideoSection = () => {
  return (
    <MainSection>
      <MainContainer>
        {/* <VideoWrapper>
          <video src={video} loop controls></video>
        </VideoWrapper> */}

        <Title>
          Arganacoin
          <br />
          <p>Video Promo</p>
        </Title>

        <MainWrapper>
          <div>
            <Vimeo video="https://vimeo.com/953689591?share=copy" autoplay />
            <p>Version française</p>
          </div>

          <div>
            <Vimeo video="https://vimeo.com/953698211?share=copy" autoplay />
            <p>Version anglaise</p>
          </div>
        </MainWrapper>

        <MobileWrapper>
          <div>
            <Vimeo
              video="https://vimeo.com/953689591?share=copy"
              autoplay
              width={320}
            />
            <p>Version française</p>
          </div>

          <div>
            <Vimeo
              video="https://vimeo.com/953698211?share=copy"
              autoplay
              width={320}
            />
            <p>Version anglaise</p>
          </div>
        </MobileWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default VideoSection;
