import { aboutimg1, aboutimg2, tree1, tree2 } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import {
  ImgDiv,
  ImgDiv2,
  ImgWrapper,
  MainSection,
  Text1,
  Text2,
  Title,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const AboutComp = () => {
  return (
    <MainSection id="about">
      <MainContainer>
        <AnimationOnScroll animateIn="animate__flipInX" animateOnce>
          <Title>
            ARGANACOIN
            <br />
            <p>SOUTENEZ LE JETON ARG</p>
          </Title>
        </AnimationOnScroll>
        <ImgDiv>
          <Image src={tree1} />
        </ImgDiv>
        <MainRow>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <Text1>
                <ul>
                  <li>
                    Le jeton <strong>ARG</strong> sera disponible à l’achat
                    durant l’offre initiale des jetons (ICO) sur une période de
                    24 mois, avec deux tarifs différents pour chaque année
                    consécutive.
                  </li>
                  <li>
                    Au lancement de l’ICO, <strong>ARG</strong> sera échangeable
                    au prix de <strong>0,25 €/c (soit 0,27 USDT).</strong>
                  </li>
                  <li>
                    Des tarifs spéciaux seront offerts aux <strong>500</strong>{" "}
                    premiers clients/investisseurs ayant soutenu l’acquisition
                    de jetons ARG.
                  </li>
                  <li>
                    "Notre feuille de route inclut la création d'une plateforme
                    évolutive, semblable au système de « growfunding »,
                    permettant le parrainage d'arbres et de divers actifs
                    agricoles via des NFT."
                  </li>
                  <li>
                    Les détenteurs de jetons ARG recevront des récompenses en
                    fonction de leur participation au projet, pour toutes les
                    initiatives de promotion proactive et de communication
                    marketing partagée. La distribution des récompenses sera
                    proportionnelle au nombre de jetons détenus.
                  </li>
                  <li>
                    Le programme ambitieux de notre équipe nécessite un
                    calendrier mesuré. Ensemble, construisons progressivement
                    cet écosystème.
                  </li>

                  <p>
                    Rejoignez notre communauté via nos différents canaux de
                    communication.
                  </p>
                </ul>
              </Text1>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
              <ImgWrapper>
                <Image src={aboutimg1} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
            <ImgDiv2>
              <Image src={tree2} />
            </ImgDiv2>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__flipInX" animateOnce>
          <Title className="title2">
            ARGANACOIN
            <br />
            <p>Qu'est-ce que ARGANACOIN (ARG) ?</p>
          </Title>
        </AnimationOnScroll>
        <MainRow className="main-row">
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <ImgWrapper className="img-wrapper3">
                <Image src={aboutimg2} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
              <Text2>
                <p>
                  <span>ARGANACOIN (ARG)</span> est un projet de crypto
                  utilitaire dédié à l'agronomie. Il vise à établir un
                  partenariat gagnant avec les domaines agricoles en tirant les
                  nombreux avantages de la technologie Blockchain.
                  <br />
                  <br />
                  Nous conduirons notre jeton Arganacoin à créer son propre
                  environnement numérique, avec la mise en place progressive de
                  :
                </p>
                <div style={{ marginLeft: "-1rem" }}>
                  <ul>
                    <li>
                      Une plateforme numérique évolutive{" "}
                      <strong>(SEED IT YOURSELF)</strong> : Un système original
                      de parrainage d’arganiers et de diverses variétés
                      agricoles par l’intermédiaire de NFTs.
                    </li>
                    <li>
                      Une marketplace <strong>(ARGANAGOLD)</strong> destinée à
                      l’acquisition de nos NFTs.
                    </li>
                    <li>
                      Soutien à l’optimisation des{" "}
                      <strong>smart-coopératives</strong> ou exploitations
                      connectées grâce à la démocratisation de l’IoT (Internet
                      des objets).
                    </li>
                    <li>
                      Une plateforme e-commerce <strong>(ARGANAZONE)</strong>{" "}
                      exclusivement conçue pour fédérer et promouvoir les
                      marques et labels nationaux, les coopératives, ainsi que
                      les petites et moyennes entreprises respectant les normes
                      internationales établies.
                    </li>
                    <li>
                      Actions proactives et investissements structurels destinés
                      à la préservation des biosphères et des patrimoines
                      forestiers d’arganiers.
                    </li>
                    <li>
                      Soutien et solutions pour le monde rural, les coopératives
                      agricoles et les ayants droit.
                    </li>
                  </ul>
                </div>
                <h5>
                  (Consultez le Livre Vert Arganacoin - Répartition des jetons
                  ARG)
                </h5>
              </Text2>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default AboutComp;
