import { roadmapimg, roadmapresponsive, tree3 } from "assets";
import { MainCol, MainContainer } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import { data } from "./data";
import {
  HeaderTitle,
  ImgDiv,
  ImgWrapper,
  MainSection,
  MainWrapper,
  MobileTextDiv,
  ResponsiveRoadmapWrapper,
  Text,
  TextDiv,
  Title,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const RoadmapSection = () => {
  return (
    <MainSection id="roadmap">
      <MainContainer>
        <ImgDiv>
          <Image src={tree3} />
        </ImgDiv>
        <AnimationOnScroll animateIn="animate__flipInX" animateOnce>
          <Title>
            Feuille de Route
            <br />
            <p>Notre feuille de route</p>
          </Title>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
          <MainWrapper>
            <MainCol lg={12}>
              <Image src={roadmapimg} fluid />
            </MainCol>
            {data.map((item, index) => (
              <TextDiv
                className={
                  (index == 0 && "first-div") ||
                  (index == 1 && "second-div") ||
                  (index == 2 && "third-div") ||
                  (index == 3 && "fourth-div") ||
                  (index == 4 && "fifth-div") ||
                  (index == 5 && "sixth-div")
                }
              >
                {index === 1 || index === 3 ? (
                  <>
                    {/* <Text>{item.text}</Text> */}
                    <ul
                      style={{
                        marginLeft: "-1.8rem",
                      }}
                    >
                      {item?.typeData?.map((it) => (
                        <li
                          key={it.id}
                          style={{ color: "#5EA624", marginBottom: "-0.7rem" }}
                        >
                          <Text>{it.value}</Text>
                        </li>
                      ))}
                    </ul>
                    <HeaderTitle>{item.title}</HeaderTitle>
                  </>
                ) : (
                  <>
                    <HeaderTitle>{item.title}</HeaderTitle>
                    {/* <Text>{item.text}</Text> */}
                    <ul
                      className=""
                      style={{
                        marginLeft: "-1.8rem",
                      }}
                    >
                      {item?.typeData?.map((it) => (
                        <li
                          key={it.id}
                          className=""
                          style={{ color: "#5EA624", marginBottom: "-0.7rem" }}
                        >
                          <Text>{it.value}</Text>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </TextDiv>
            ))}
          </MainWrapper>
        </AnimationOnScroll>
        <ResponsiveRoadmapWrapper>
          <ImgWrapper>
            <Image src={roadmapresponsive} />
          </ImgWrapper>

          {data.map((item, index) => (
            <MobileTextDiv
              className={
                (index == 0 && "first-div") ||
                (index == 1 && "second-div") ||
                (index == 2 && "third-div") ||
                (index == 3 && "fourth-div") ||
                (index == 4 && "fifth-div")
              }
            >
              <>
                <HeaderTitle>{item.title}</HeaderTitle>
                {/* <Text>{item.text}</Text> */}
                <ul
                  className=""
                  style={{
                    marginLeft: "-1.8rem",
                  }}
                >
                  {item?.typeData?.map((it) => (
                    <li
                      key={it.id}
                      className=""
                      style={{ color: "#5EA624", marginBottom: "-0.7rem" }}
                    >
                      <Text>{it.value}</Text>
                    </li>
                  ))}
                </ul>
              </>
            </MobileTextDiv>
          ))}
        </ResponsiveRoadmapWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default RoadmapSection;
