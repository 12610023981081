export const data = [
  {
    id: 1,
    title: `2022`,
    typeData: [
      {
        value: "Constitution du Livre Vert  & Ecosystème \n ARGANACOIN ",
      },

      {
        value: "Prospection de Partenariat  (Morocco) ",
      },
      {
        value: "Prospection de Partenariat  (Blockchain)",
      },
    ],
    text: `Constitution du Livre Vert \n & Ecosystème ARGANACOIN \n Prospection de Partenariat \n (Morocco) \n Prospection de Partenariat \n (Blockchain)`,
  },
  {
    id: 1,
    title: `2023`,
    typeData: [
      {
        value: `Création du smart contrat (Jeton Arganacoin). `,
      },

      {
        value: `Marketing & Communication.`,
      },
      {
        value: `Finalisation des partenariats.`,
      },
    ],
    text: `Création du Contrat intelligent jeton\n   ARGANACOIN - ARG \n Marketing & Communication\n  Promotion du\n  jeton ARGANACOIN \n Vente privée du jeton\n  ARGANACOIN (spécial ICO) \n Finalisation des Partenariats (Maroc) \n Réflexion et architecture de la\n  Plateforme digitale\n  « SEED IT YOURSELF » Parrainage\n  d’Arganiers via des NFTs`,
  },
  {
    id: 1,
    title: `2024`,

    typeData: [
      {
        value: `Communication & Marketing (ICO 1er tarif). \n Offre initiale de jetons.`,
      },

      {
        value: `Promotion du projet (réseaux sociaux).`,
      },
      {
        value: `Réflexion et architecture de la plateforme \n « SEED IT YOURSELF ».`,
      },
      {
        value: "Parrainage des arganiers via des NFTs.",
      },
    ],
    text: `Création des Contrats Intelligents \n  NFTs \n Création de la Plateforme\n  Marketplace NFT - ARGANAGOLD \n Développement des projets pour \n la protection de l’Arganier \n Présentation de la Plateforme\n  « SEED IT YOURSELF » `,
  },
  {
    id: 1,
    title: `2025`,
    typeData: [
      {
        value: `Communication & Marketing (ICO 2ème tarif).`,
      },

      {
        value: `Créations des smart contrats NFTs.`,
      },
      {
        value: `Création de la plateforme Marketplace NFTs \n « ARGANAGOLD ».`,
      },
      {
        value: `Développement des projets pour la \n préservation des arganiers.`,
      },
      {
        value: `Présentation de la plateforme « SEED IT \n YOURSELF ».`,
      },
    ],
    text: `Réflexion et architecture de la Plateforme « ARGANA KINGDOM & ARGANAZONE » portail commercial et international dédié Aux coopératives agricoles marocaines\n Développement réseaux et promotion de la Plateforme marchande \n  « ARGANA KINGDOM »\n Listing du jeton ARGANACOIN sur les\n Plateformes d ’exchanges`,
  },

  {
    id: 1,
    title: `2026`,
    typeData: [
      {
        value: `Réflexion et architecture de la plateforme \n e-commerce « ARGANAZONE ».`,
      },

      {
        value: `Développement des réseaux et promotion \n marchande « ARGANAZONE ».`,
      },
      {
        value: `Listing du jeton ARGANACOIN sur les \n plateformes d’échanges.`,
      },
    ],
    text: `Réflexion et architecture de la Plateforme « ARGANA KINGDOM & ARGANAZONE » portail commercial et international dédié Aux coopératives agricoles marocaines\n Développement réseaux et promotion de la Plateforme marchande \n  « ARGANA KINGDOM »\n Listing du jeton ARGANACOIN sur les\n Plateformes d ’exchanges`,
  },
];
