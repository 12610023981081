import {
  facebookicon,
  instaicon,
  joinimg2,
  linkdenicon,
  telegramicon,
  twittericon,
} from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import {
  ImgDiv,
  MainSection,
  MainWrapper,
  SocialIconDiv,
  Title,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const JoinSection = () => {
  const handleTwitterClick = () => {
    window.open("https://twitter.com/arganacoin", "_blank");
  };

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/arganacoin", "_blank");
  };
  const handleLinkdenClick = () => {
    window.open("https://www.linkedin.com/company/arganacoinarg/", "_blank");
  };
  const handletelegramClick = () => {
    window.open("https://t.me/+tzZak84gnH0zOTY0", "_blank");
  };
  const handleinstagramClick = () => {
    window.open("https://www.instagram.com/arganacoin/", "_blank");
  };

  return (
    <MainSection>
      <MainWrapper>
        <MainContainer>
          <MainRow>
            <MainCol lg={7}>
              <AnimationOnScroll animateIn="animate__flipInX" animateOnce>
                <Title>Rejoignez-Nous</Title>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
                <SocialIconDiv>
                  <Image src={twittericon} onClick={handleTwitterClick} />
                  <Image src={instaicon} onClick={handleinstagramClick} />
                  <Image src={facebookicon} onClick={handleFacebookClick} />
                  <Image src={linkdenicon} onClick={handleLinkdenClick} />
                  <Image src={telegramicon} onClick={handletelegramClick} />
                </SocialIconDiv>
              </AnimationOnScroll>
            </MainCol>
            <MainCol lg={5}>
              <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
                <ImgDiv>
                  <Image src={joinimg2} />
                </ImgDiv>
              </AnimationOnScroll>
            </MainCol>
          </MainRow>
        </MainContainer>
      </MainWrapper>
    </MainSection>
  );
};

export default JoinSection;
