import { logo } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import { ImgDiv, LastText, LinkDiv, MainSection, Text } from "./elements";

const FooterSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <MainRow>
          <MainCol lg={4}>
            <ImgDiv>
              <Image src={logo} fluid />
            </ImgDiv>

            <Text>
              Nous créons un écosystème numérique unique avec un jeton
              utilitaire soutenu par des actifs naturels, favorisant la
              durabilité et la croissance éthique.
            </Text>
          </MainCol>

          <MainCol lg={2}>
            <LinkDiv className="last-div1">
              <h5>Liens utiles</h5>
              <p>support@arganacoin.io</p>
              <p>help@arganacoin.io</p>
            </LinkDiv>
          </MainCol>
          <MainCol lg={2}>
            <LinkDiv className="last-div">
              <h5>Contact</h5>
              <p>contact@arganacoin.io</p>
              <p onClick={() => window.open("https://www.arganacoin.io/")}>
                https://arganacoin.io/
              </p>
            </LinkDiv>
          </MainCol>
          <MainCol lg={3}>
            <LinkDiv className="last-div">
              <h5>Emplacement du Bureau</h5>
              <p>AGADIR - MAROC</p>
            </LinkDiv>
          </MainCol>
        </MainRow>
        <LastText>
          Droits D'auteur : © {new Date().getFullYear()} Arganacoin. Tous Droits
          Réservés
        </LastText>
      </MainContainer>
    </MainSection>
  );
};

export default FooterSection;
