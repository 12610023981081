import styled from "styled-components";

export const MainSection = styled.section`
  position: relative;

  .shadow {
    position: absolute;
    inset: 0;
    border-radius: 1147px;
    background: #5ea624;
    filter: blur(501.18182373046875px);
    z-index: -1;
  }
`;

export const Title = styled.h5`
  margin-top: -2rem;

  color: rgba(61, 64, 68, 0.75);
  text-align: center;
  font-family: "Zen Dots";
  font-size: 58.917px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58.917px */
  text-transform: capitalize;
  @media (max-width: 990px) {
    margin-top: -3rem;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }

  p {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 29.459px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 29.459px */
    text-transform: capitalize;
    transform: translateY(-1rem);

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  &.title2 {
    margin-top: -1.5rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
    }
  }
`;

export const ImgDiv = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-bottom: 6rem;
  img {
    /* width: 858px;
    height: 631px; */

    @media (max-width: 990px) {
      width: 100%;
      height: auto;
      padding: 0px 1rem;
    }
  }

  @media (max-width: 990px) {
    margin-top: 1rem;
    padding-bottom: 3rem;
  }
`;
