import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    margin-top: 6rem;
  }
`;

export const VideoWrapper = styled.div`
  video {
    /* width: 100%; */
    height: 704px;
    border: 6px solid #5ea624;

    @media (max-width: 1400px) {
      width: 100%;
    }
    @media (max-width: 990px) {
      height: 550px;
    }

    @media (max-width: 767px) {
      height: 210px;
      border: none;
    }
  }
`;

export const Text = styled.div`
  color: #5ea624;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 20.459px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%; /* 29.459px */
  text-transform: capitalize;
  margin-top: 2rem;
  cursor: pointer;
  /* text-decoration: underline; */
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 5rem;

  @media (max-width: 990px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 500px) {
    display: none;
  }

  p {
    text-align: center;
    color: white;
    text-transform: uppercase;
    margin-top: 1rem;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;

  @media (min-width: 500px) {
    display: none;
  }

  p {
    text-align: center;
    color: white;
    text-transform: uppercase;
    margin-top: 1rem;
  }
`;

export const Title = styled.h5`
  margin-top: -2rem;

  color: rgba(61, 64, 68, 0.75);
  text-align: center;
  font-family: "Zen Dots";
  font-size: 58.917px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58.917px */
  text-transform: capitalize;
  @media (max-width: 990px) {
    margin-top: -3rem;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }

  p {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 29.459px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 29.459px */
    text-transform: capitalize;
    transform: translateY(-1rem);

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  &.title2 {
    margin-top: -1.5rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
    }
  }
`;
